import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import FormWithLocalStorage from './components/Form';
//import TableWithLocalStorage from './components/Table';
import Upcoming from './components/Upcoming';
import { BrowserRouter, Routes, Route } from 'react-router-dom';



const App = () => {
  const [currentPage, setCurrentPage] = useState('home'); // default content

  const handlePageChange = (page) => {
    setCurrentPage(page); // update the page to render
  };

{/*
  const checkWalletAddress = async (walletAddress) => {
    try {
      const res = await axios.get(
        `http://localhost:4000/checkWalletAddress/${walletAddress}`
      );
      if (blah) {
         return blah;
      } else {
        return blahblah;
      }
    } catch (err) {
      console.log(err);
    }
  };
*/}
  return (
   
    <div className="App">
   {/*   <header className="App-header">

      </header>
  */}

<h1 >Chilzy</h1>

<FormWithLocalStorage></FormWithLocalStorage>
<Upcoming></Upcoming>

 {/*
 <TableWithLocalStorage></TableWithLocalStorage>

 <button onClick={() => handlePageChange('form')}>form</button>


<div className="content">
        {currentPage === 'form' && <FormWithLocalStorage />}
      </div>


  */}

   


{/*
<ul>
  {checkWalletAddress.map(testing => (
    <li key={testing._id}>
      <h3>{testing.name}</h3>
      <p>{testing.description}</p>
    </li>
  ))}
</ul>
*/}


 {/* User is logged in based off their cellphone - hashed url */}





 {/* onclick this opens a modal to send request, with number / Day / Time / Location */}





  {/*

<form action="mailto:6512336987@vtext.com" method="post" enctype="text/plain">
Name:
<br/>
<input type="text" name="name"/><br/>
E-mail:<br/>
<input type="text" name="mail"/>
<br/>
Comment:<br/>
<input type="text" name="comment" size="50"/>
  <br/><br/>
<input type="submit" value="Send"/>
<input type="reset" value="Reset"/>


</form>

*/}




 

      
    </div>
  );
}

export default App;
