import React, { useState, useEffect } from 'react';


const Upcoming = () => {
   
  
    return (
      <div>
        <h2>Upcoming Chills</h2>
              <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FChicago&showPrint=0&mode=AGENDA&showTitle=0&showDate=0&showTz=0&src=ZmFtaWx5MDI1MjMwODA3NjQxNzgzMDY0MTdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%239E69AF" style={{borderWidth:0, width:'100%', height:600, frameborder:0, scrolling:"no"}}></iframe>

      </div>
    );
  };

export default Upcoming;

