import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
//import CalendarModal from './CalendarModal';
//    text=Example+Google+Calendar+Event&details=More+help+see:+https://support.google.com/calendar/thread/81344786&dates=20201231T160000/20201231T170000


const FormWithLocalStorage = () => {
  // Initialize state with data from localStorage, if available
  //const [formData, setFormData] = useState(() => {
  //  const savedData = localStorage.getItem('formData');
  //  return savedData ? JSON.parse(savedData) : { testevent: 'hi', name: '', email: '', month: '', day: '', year: '', startTime: '', endTime: ''};
  //});

  // Handle form input changes
  //const handleChange3 = (event) => {
  //  const { name, value } = event.target;
    //if ({name} = 'year') 
    //{console.log('hello!')}
    //{
    //setFormData(prevState => ({
    //  ...prevState,
    //  [name]: 'test2',
    //}));}

    //else {
   // setFormData(prevState => ({
   //   ...prevState,
   //   [name]: value,
  //  }));
  //}
  //};

//const handleChange2 = (event) => {
//  const { name, value } = event.target;
  //if ({name} = 'year') 
  //{console.log('hello!')}
  //{
  //setFormData(prevState => ({
  //  ...prevState,
  //  [name]: 'test2',
  //}));}

  //else {
//  setFormData(prevState => ({
//    ...prevState,
//    [name]: value,
//  }));
//}

  // Save data to local storage whenever formData changes
  //useEffect(() => {
  //  if (formData) {
  //    localStorage.setItem('formData', JSON.stringify(formData));
  //  }
  //}, [formData]);


  const [inputs, setInputs] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
  
    let filteredValue = '';

     // Create a new string excluding the letter 'f'
  for (let i = 0; i < value.length; i++) {
   // if (value[i].toLowerCase() !== 'f') {  // Checking for both lowercase and uppercase 'f'
   if (name == 'year' && value[i] !== '-') {
      filteredValue += value[i];
    }
  }
  console.log(value)
console.log(filteredValue)
  localStorage.setItem('testing',filteredValue);
  
   // setInputs(values => ({...values, [name]: filteredValue}))
  }

  const handleChange2 = (event) => {
    const name = event.target.name;
    const value = event.target.value;
  

  console.log(value)
  setInputs(values => ({...values, [name]: value}))

  }



  const handleChange3 = (event) => {
    const name = event.target.name;
    const value = event.target.value;
  
    let filteredValue2 = '';

    // Create a new string excluding the letter 'f'
 for (let i = 0; i < value.length; i++) {
  // if (value[i].toLowerCase() !== 'f') {  // Checking for both lowercase and uppercase 'f'
  if (name == 'startTime' && value[i] !== ':') {
     filteredValue2 += value[i];
   }
 

  }



 console.log(value)
console.log(filteredValue2)

 localStorage.setItem('testStartTime',filteredValue2);

 

  }


  const handleChange4 = (event) => {
    const name = event.target.name;
    const value = event.target.value;
  
    let filteredValue3 = '';


   for (let i = 0; i < value.length; i++) {
 if (name == 'endTime' && value[i] !== ':') {
  filteredValue3 += value[i];
}
 }
 console.log(value)
console.log(filteredValue3)

 localStorage.setItem('testEndTime',filteredValue3);


  }


  const handleClick = () => {
    const hello = localStorage.getItem('testing');
    const starterTest = localStorage.getItem('testStartTime');
    const enderTest = localStorage.getItem('testEndTime')
    const windowFeatures = 'width=800,height=50,scrollbars=yes,resizable=yes,menubar=no,status=no';

   window.open(`https://calendar.google.com/calendar/u/0/r/eventedit?text=${inputs.testevent}&details=Brought+to+you+by+chilzy.com&dates=${hello}T${starterTest}00/${hello}T${enderTest}00&add=${inputs.email}`, '_blank', windowFeatures);
    

  }


  const closeModal = () => setIsModalOpen(false);

  // Handle form submission
 // const handleSubmit = (event) => {
  //  event.preventDefault();
 //   alert('Form submitted with data: ' + JSON.stringify(formData));
   // Optionally, clear localStorage after submit
  //   localStorage.removeItem('formData');
  //};

  //const [showIframe, setShowIframe] = useState(false);

  //const handleOpenIframe = () => {
   // setShowIframe(true);
//{formData.Event...}
//const fruits = ['Apple', 'Banana', 'Cherry']; 


//for (let i = 0; i < fruits.length; i++) { 
//	console.log(fruits[i]); 
//}

//const jimmy = jimbo => {
 // let fruits = ['Apple', 'Banana', 'Cherry']; 
 //   for (let item of jimbo) {
 //  fruits[item]
 //   }}

// const {testevent} = '10/1/2024';

const testHandle = (event) => {
  let contents = [];
  contents.push('heythere')

  localStorage.setItem('test', JSON.stringify(contents));
};

  //const { name, value } = event.target;
  //if ({name} = 'year') 
  //{console.log('hello!')}
  //{
  //setFormData(prevState => ({
   // ...prevState,
   // [name]: 'test2',
  //}));}

  //else {
//  setFormData(prevState => ({
//    ...prevState,
//    [name]: value,
//  }));
//}


// Handle submit - test append to local storage
const testappendButton = content => {

  let contents = [];
  contents.push('test')
  //for (let i = 0; i< contents.length; i++) {
  //  const item = animals[i];
  //  contents.push('hello');
  //  localStorage.setItem('test', JSON.stringify(content));

  //}
  localStorage.setItem('test', JSON.stringify(contents));
  //localStorage.setItem('test'), JSON.stringify(content);
}


// Handle button - remove local storage items:
const handleButton = (event) => {
  event.preventDefault();
  localStorage.removeItem('formDatahello')
  localStorage.removeItem('formData')
  localStorage.removeItem('formData2')
  localStorage.removeItem('formData3')
  localStorage.removeItem('formDataformData')
  localStorage.removeItem('tableData')
  localStorage.removeItem('test')
  localStorage.removeItem('testing')


}

  return (
    <div>
      
    <form >
    <div>
        <label htmlFor="testevent">Event:</label>
        <input
          type="text"
          id="testevent"
          name="testevent"
          value={inputs.testevent}
          onChange={handleChange2}
        />
      </div>
    
    
      <div>
        <label htmlFor="year">Date:</label>
        <input
          type="date"
          id="year"
          name="year"
          value={inputs.year}
          onChange={handleChange}

        />
      </div>
      <div>
        <label htmlFor="startTime">Start Time:</label>
        <input
          type="time"
          id="startTime"
          name="startTime"
          value={inputs.startTime}
          onChange={handleChange3}
        />
      </div>
      <div>
        <label htmlFor="endTime">End Time:</label>
        <input
          type="time"
          id="endTime"
          name="endTime"
          value={inputs.endTime}
          onChange={handleChange4}
        />
      </div>

      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={inputs.email}
          onChange={handleChange2}
        />
      </div>

  
      <Button variant="secondary" onClick = {handleClick}>Submit</Button>


   

    </form>

{/*
    <a href={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${formData99.event}&details=Brought+to+you+by+chilzy.com&dates=${formData99.year}T${formData99.startTime}0000/${formData99.year}T${formData99.endTime}0000&add=${formData99.email}`} target='_blank'>


    <Button variant="secondary" onClick = {handleButton}>Remove local storage</Button>
    <Button variant="secondary" onClick = {testappendButton}>Test append to local storage</Button>

  */}





    {/* 
    <a href={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${formData.event}&details=Brought+to+you+by+chilzy.com&dates=${formData.year}${formData.month}${formData.day}T${formData.startTime}0000/${formData.year}${formData.month}${formData.day}T${formData.endTime}0000&add=${formData.email}`}>
    
    
   
    
    <button onClick={handleOpenIframe}>Open External Content</button>

    {showIframe && (

   <iframe
        src={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${formData.event}&details=Brought+to+you+by+chilzy.com&dates=${formData.year}${formData.month}${formData.day}T${formData.startTime}0000/${formData.year}${formData.month}${formData.day}T${formData.endTime}0000&add=${formData.email}`}

        width="600" 
        height="400" 
        title="External Content"
   ></iframe>
      )}
   
    <Button variant="secondary">Send Chilzy Request</Button>
    </a>


    <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FChicago&showPrint=0&mode=AGENDA&showTitle=0&showNav=0&showDate=0&showTabs=0&showTz=0&src=ZmFtaWx5MDI1MjMwODA3NjQxNzgzMDY0MTdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%239E69AF" style={{borderWidth:0, width:800, height:600, frameborder:0, scrolling:"no"}}></iframe>

    */} 
    </div>
  );
};
//}
export default FormWithLocalStorage;







